import { Box } from '@tofu/shared/ui/atoms/box'
import { getFromMap } from '@tofu/shared/utils/get-from-map'

import { spacingMap } from './slice-spacing.constants'
import { TSliceSpacing } from './slice-spacing.types'

export const SliceSpacing: TSliceSpacing = ({ primary }) => {
  const { spacing, background = 'white' } = primary
  const height = getFromMap(spacingMap, spacing) || 0

  return <Box height={height} bg={background} />
}
